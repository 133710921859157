import React, { useState } from 'react';
import { login, helloWorld } from '../api';

function LoginForm() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token'));

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await login(username, password);
            setToken(response.data.token);
            localStorage.setItem('token', response.data.token);
            alert('Login successful!');
        } catch (error) {
            alert('Login failed: ' + error.response.data);
        }
    };

    const handleHelloWorld = async () => {
        try {
            const response = await helloWorld(token);
            alert(response.data.greeting);
            console.log(response.data);
        } catch (error) {
            alert('Failed to fetch: ' + error.response.data);
        }
    };

    const handleLogout = async () => {
        try {
            localStorage.clear();
            setToken(null);
        } catch (error) {
            alert('Failed to fetch: ' + error.response.data);
        }
    };

    return (
        <div style={{height: 200, backgroundColor: '#B9CDDA', marginTop: 20}}>
            {!token && (
                <form onSubmit={handleLogin} style={{ padding: '1em'}}>
                    <h2>Login</h2>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Login</button>
                </form>
            )}
            
            {token && (
                <div style={{ padding: '1em'}}>
                    <button onClick={handleHelloWorld}>
                        Say Hello
                    </button>
                    <button onClick={handleLogout}>
                        Logout
                    </button>
                </div>
                
            )}
        </div>
    );
}

export default LoginForm;
