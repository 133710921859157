import React from 'react';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';

function App() {
    return (
        <div>
            <h1 style={{marginLeft: '1em'}}>Brute-force passwords</h1>
            <RegisterForm />
            <LoginForm />
        </div>
    );
}

export default App;
