import axios from 'axios';

const API_URL = '/api';

export const register = (username, password) => {
    return axios.post(API_URL + '/auth/register', { username, password });
};

export const login = (username, password) => {
    return axios.post(API_URL + '/auth/login', { username, password });
};

export const helloWorld = (token) => {
    return axios.get(API_URL + '/auth/helloworld', {
        headers: { 'Authorization': `Bearer ${token}` }
    });
};
